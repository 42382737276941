export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا "])},
  "navbar": {
    "primaryPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
    "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية "])},
    "aboutus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نبذة عنا "])},
    "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الاستخدام "])},
    "Q&A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الأسئلة الشائعة "])},
    "downloadapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل التطبيق"])}
  },
  "home": {
    "cardTmwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البطاقة التموينية"])},
    "starthometitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبدأ بمعاملتك الاَن وبسهولة بإستخدام هاتفك المحمول, وقم بمتابعة خالة معاملاتك مباشرةً."])},
    "startbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبدأ معاملتك الآن"])},
    "abouttmwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نبذة عن البطاقة التموينية الألكترونية"])},
    "abouttmwindesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يهدف مشروع البطاقة التموينية الإلكترونية لتحويل البطاقة التموينية الورقية الى الكترونية من خلال تطبيق خاص لكل من الوكيل والمواطن، سيسهل التطبيق عملية تسليم مواد الحصة التموينية وكذلك جميع المعاملات التي تخص التعديلات مثل إضافة او حذف الأشخاص وغيرها من التعديلات الأخرى. يقدم مشروع البطاقة التموينية الإلكترونية حلاَ أكثر موثوقية وسهولة في الإستخدام لكل من العائلات المستفيدة من البطاقة التموينية وكذلك موظفي الحكومة العراقية."])},
    "usingtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل البطاقة التموينية الألكترونية"])},
    "usingdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيساعدك هذا الدليل على التقديم بشكل صحيح"])},
    "card1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل معلوماتك الصحيحة:"])},
    "card1desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند تقديمك على البطاقة التموينية الإلكترونية، يرجى مراعاة إدخال معلوماتك الشخصية (الاسم، رقم البطاقة الوطنية، العنوان، معلومات مركز التموين) بشكل صحيح لتجنب التعرض للمسائلة القانونية."])},
    "card2title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستخدم رقم هاتف صحيح:"])},
    "card2desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند التسجيل في التطبيق، يرجى إستخدام رقم هاتفك الصحيح لكي يتم إعتماده في المعاملات والتعديلات والإشعارات لاحقاً، ولا تقم بالتسجيل لأكثر من مرة."])},
    "card3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستخدم الوثائق الرسمية الصحيحة:"])},
    "card3desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند التقديم يرجى مراعاة إدخال الوثائق الثبوتية الرسمية الخاصة بك (البطاقة الوطنية، بطاقة السكن، البطاقة التموينية القديمة) لضمان عملية تسجيل صحيحة."])},
    "card4title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنتبه للإشعارات:"])},
    "card4desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى متابعة الإشعارات التي ستصلك عبر تطبيق الهاتف المحمول للاطلاع على آخر التحديثات، والحصول على المعلومات المتعلقة ببطاقتك التموينية الإلكترونية."])},
    "allright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمیع الحقوق محفوظة لوزارة التجارة العراقية"])},
    "questins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسئلة الشائعة"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻣﺎھﻲ اﻟوﺛﺎﺋق اﻟﻣطﻠوﺑﺔ ﻟﻐرض اﻟﺗﻘدﯾم ﻋﻠﻰ اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻹﻟﻛﺗروﻧﯾﺔ؟"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﺗم إﻋﺗـﻣﺎد اﻟﺑطﺎﻗﺔ اﻟوطﻧﯾﺔ اﻟﻣوﺣدة وﻣﻌﻠوﻣﺎت اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻟورﻗﯾﺔ )اﻟﻣﻌﻣول ﺑﮭﺎ ﺳﺎﺑﻘﺎ( ﻟﻐرض اﻟﺗﻘدﯾم ﻋﻠﻰ اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻹﻟﻛﺗروﻧﯾﺔ"])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻣﺎ ھو اﻻﺟراء اﻟﻣﻧﺎﺳب ﻟﺗﺻﺣﯾﺢ اﻟﻣﻌﻠوﻣﺎت ﻓﻲ ﺣﺎل ﺗم رﻓﻌﮭﺎ ﺑﺻورة ﺧﺎطﺋﺔ؟"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﻣﻛن ﺗﻌدﯾل اﻟﻣﻌﻠوﻣﺎت ﻣن ﺧﻼل اﻟﺗطﺑﯾق ﻋن طرﯾق واﺟﮭﺔ اﻟﻣﺳﺗﺧدم"])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻣـﺎ ھـو اﻹﺟـراء اﻟﻣﻧـﺎﺳب، ﻋﻧـد وﺟـود ﻧﻘـص ﻓﻲ أﺣـد أﻓراد اﻷﺳرة؟"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﺟب إﻛﻣﺎل ﻋﻣﻠﯾﺔ اﻟﺗﻘدﯾم ﻟﻸﻓراد اﻟﻣوﺟودﯾن ﻓﻘط ﺣﺳب ﻣﻌﻠوﻣﺎت اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ، واﺿﺎﻓﺔ اﻟﻔرد ﻓﻲ وﻗت ﻻﺣق ﻋن طرﯾق اﻟﺗطﺑﯾق"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻓﻲ ﺣﺎل ﻧﺳﯾﺎن او ﺿﯾﺎع رﻗم اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ او ﻛﻠﻣﺔ اﻟﻣرور, ﻣﺎ ھـو اﻹﺟراء اﻟﻼزم ﻟﺗﺳﺟﯾل اﻟدﺧول ﻟﻠﺗطﺑﯾق؟"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﻣـﻛن طـﻠب ﻣﻌﻠوﻣﺎت ﺟدﯾدة ﻟﻐرض ﺗﺳﺟـﯾل اﻟدﺧول ﻣن ﺧﻼل اﻟﺿﻐط ﻋـﻠﻰ \" ﻧﺳﯾت ﻛﻠﻣﺔ اﻟﻣرور؟\" وﺳﯾﺗم إرﺳﺎل رﻣز ﺗﺣﻘق وﻣن ﺛم ﻣﻌﻠوﻣﺎت ادﺧﺎل ﻛﻠﻣﺔ ﻣرور ﺟدﯾدة"])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻓﻲ ﺣﺎل ﺗوﻗـف او ﺿﯾﺎع رﻗم اﻟﮭـﺎﺗف اﻟﻣﺳﺟل ﻓﻲ اﻟﺗطﺑﯾق, ﻛﯾف ﯾﻣﻛن ﺗﻐﯾﯾـر رﻗم اﻟﮭـﺎﺗف؟"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﻣﻛـن ﺗﻐﯾﯾـر رﻗم اﻟﮭـﺎﺗف اﻟﻣﺳﺗﺧدم ﻓﻲ اﻟﺗطﺑﯾق ﻣن ﺧﻼل ﺗﻌدﯾل اﻟﺑﯾﺎﻧﺎت اﻟﺷﺧﺻﯾﺔ, وﻓﻲ ﺣـﺎل ﻛﺎن اﻟﺗطﺑﯾـق ﯾﺣﺗﺎج إﻟـﻰ إﻋـﺎدة ﺗﺳﺟﯾل دﺧـول ﯾﻣﻛن اﻟﺗواﺻل ﻣﻊ ﻓرﯾـق ﺧدﻣﺔ اﻟﻌﻣﻼء ﻟﻠﺗﺄﻛد ﻣن ھوﯾﺔ اﻟﻣﺳﺗﺧدم وﺗﻐﯾﯾر اﻟﺑﯾﺎﻧﺎت اﻟﻣطﻠوﺑﺔ"])},
    "downloadapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمل التطبيق الآن:"])},
    "regsternow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقم بالتسجيل بشكل مباشر"])},
    "followus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تابعنــــــــــــــا عــلى"])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ھـل ﯾوﺟـد ﻣـوﻋد ﻣﺣـدد ﻟﻧﮭﺎﯾﺔ اﻟﺗﻘـدﯾم ﻋﻠﻰ اﻟﺑطـﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻹﻟﻛﺗروﻧﯾـﺔ؟"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اﻟﺗﻘدﯾم ﻣﺳﺗﻣر وﻻﯾوﺟد ﻣوﻋد ﻟﻧﮭﺎﯾﺔ اﻟﺗﻘدﯾم"])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻣﺎھﻲ اﻟﻠﻐﺎت اﻟﻣطﻠوب اﺳﺗﺧداﻣﮭﺎ داﺧل اﻟﺗطﺑﯾق؟"])},
    "a7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﺗﻛون اﻻرﻗﺎم ﺑﺎﻟﻠﻐﺔ اﻻﻧﻛﻠﯾزﯾﺔ وﻣلء اﻟﺑﯾﺎﻧﺎت ﺑﺎﻟﻠﻐﺔ اﻟﻌرﺑﯾﺔ او اﻟﻛردﯾﺔ"])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ھل ﯾﺣﺗﺎج اﻟﺗطﺑﯾق اﻟﻰ اﻷﻧﺗرﻧت ام ﯾﻣﻛن اﺳﺗﺧداﻣﮫ ﺑدون وﺟود اﻻﻧﺗرﻧت؟"])},
    "a8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﺟب ﺗوﻓر ﺧدﻣﺔ اﻧﺗرﻧت"])},
    "q9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ھل ﺳﯾﺗم إھﻣـﺎل اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻟورﻗﯾﺔ؟"])},
    "a9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻧﻌـم, ﺳﯾﺗم ﺗﺣوﯾل اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻟورﻗﯾﺔ اﻟﺣـﺎﻟﯾﺔ ﺣﯾث ﺳﺗﻛون ﻣدﻣﺟﺔ ﻣﻊ اﻟﺑطﺎﻗﺔ اﻟوطﻧﯾﺔ اﻟﻣوﺣدة"])},
    "q10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ھل اﻟﺗطﺑﯾق ﻓﻌﺎل ﻋﻠﻰ ﺟﻣﯾﻊ اﻷﺟﮭزة؟"])},
    "a10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اﻟﺗطﺑﯾق ﻓﻌﺎل ﻋﻠﻰ ﺟﻣﯾﻊ اﻷﺟﮭزة اﻟﺗﻲ ﺗﻌﻣل ﺑﻧظﺎم اﻷﻧدروﯾد وﻧظﺎم اﻟـIOS"])},
    "q11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ھل اﻟﺗﻘدﯾم ﻋﻠﻰ اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻻﻟﻛﺗروﻧﯾﺔ ﻣﺟﺎﻧﯾﺎً؟"])},
    "a11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻧﻌم،اﻟﺗـﻘدﯾم ﻣﺟﺎﻧﻲ ﺑﺎﻟﻛﺎﻣل ﻋﻠﻰ اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻻﻟﻛﺗروﻧﯾﺔ"])},
    "q12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻛﯾف ﯾﺗم اﻟﺗواﺻل ﻣﻊ ﻓرﯾق ﺧدﻣﺔ اﻟﻌﻣﻼء؟"])},
    "a12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﺗم اﻟﺗواﺻل ﻣﻊ ﻓرﯾق ﺧدﻣﺔ اﻟﻌﻣﻼء ﻣن ﺧﻼل اﻟﻣوﻗﻊ اﻹﻟﻛﺗروﻧﻲ وﻛذﻟك ﻣن ﺧﻼل ﺗطﺑﯾق اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻟذي ﯾﺗﯾﺢ ﻟك اﻟﺗواﺻل ﻣﻊ ﻓرﯾق اﻟدﻋم ﺑﺷﻛل ﻓﻌﺎل"])},
    "q13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻛﯾف ﯾﺗم ﺗدﻗﯾق اﻟﻣﻌﻠوﻣﺎت واﻟﺗﺄﻛد ﻣن ﺻﺣﺗﮭﺎ؟"])},
    "a13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﺗم ﺗدﻗﯾق اﻟﺑﯾﺎﻧﺎت اﻟﻛﺗروﻧﯾﺎ ً ﻋن طرﯾق اﻟﺗطﺑﯾق ﻟﻛل ﺷﺧص ﻣن اﻓراد اﻟﻌﺎﺋﻠﺔ , ﺣﯾث ﯾﺗطﻠب ﻋﻣل ﻣﺳﺢ ﻟوﺟﮭﻲ اﻟﺑطﺎﻗﺔ اﻟوطﻧﯾﺔ اﻟﻣوﺣدة واﺧذ ﺻورة ﻓورﯾﺔ ﺣﯾﺔ ﻟﻠﺷﺧص ﻋن طرﯾق اﻟﺗطﺑﯾق"])},
    "q14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻣﺎ ھو NFC وﻛﯾف ﯾﺗم اﺳﺗﺧداﻣﮫ؟"])},
    "a14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للاﺳﺗﻔﺎدة ﻣن ﺗﻘﻧﯾﺔ NFC، ﻗم ﺑوﺿﻊ اﻟﺑطﺎﻗﺔ اﻟوطﻧﯾﺔ اﻟﻣوﺣدة ﻓﻲ اﻟﺟﮭﺔ اﻟﺧﻠﻔﯾﺔ ﻟﻠﮭﺎﺗف، ﺑﺎﻟﻘرب ﻣن اﻟﻣﻧطﻘﺔ اﻟﻣﺧﺻﺻﺔ ﻟﻘراءة NFC )ﻋﺎدة ً ﺗﻛون ﻓﻲ ﻣﻧﺗﺻف اﻟﺟزء اﻟﺧﻠﻔﻲ ﻟﻠﮭﺎﺗف(. ﺗﺄﻛد ﻣن وﺿﻊ اﻟﺑطﺎﻗﺔ ﺑﺷﻛل ﺻﺣﯾﺢ ﻟﺗﺗم ﻗراءﺗﮭﺎ ﺑﺳﮭوﻟﺔ."])},
    "q15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ﻛﯾﻔﯾﺔ ﺣذف أﺣد أﻓراد اﻷﺳرة و ﻣﺎ ھﻲ اﻟﺣﺎﻻت"])},
    "a15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﻣﻛن ﻋﻣل اﻟﺗﻌدﯾﻼت او اﻟﺣرﻛﺎت ﻋﻠﻰ اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ ﻣﺛل )اﺿﺎﻓﺔ, ﺣذف, ﺷطر....اﻟﺦ( ﻋن طرﯾق ﺗطﺑﯾق اﻟﮭﺎﺗف"])},
    "q16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻛﯾف ﯾﻣﻛﻧﻧﻲ ﺗدﻗﯾق اﺣد اﻓراد اﻟﻌﺎﺋﻠﺔ اذا ﻟم ﯾﻛن ﺣﺎظر ﻣﻊ اﻟﻌﺎﺋﻠﺔ؟"])},
    "a16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﻣﻛن ارﺳﺎل راﺑط اﻟﺗدﻗﯾق ﻋن طرﯾق ﻧﺳﺧﮫ وارﺳﺎﻟﮫ اﻟﻰ اي ﻣﻧﺻﺔ ﺗﻣﻛﻧك ﻣن اﻟﺗواﺻل ﻣﻊ ﺻﺎﺣب اﻟﺑطﺎﻗﺔ واﺗﺑﺎع ﺧطوات اﻟﺗدﻗﯾق"])},
    "q17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻛﯾف ﯾﻣﻛﻧﻧﻲ اﻟﺗﻘدﯾم ﻋﻠﻰ اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻻﻟﻛﺗروﻧﯾﺔ؟"])},
    "a17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﻣﻛن اﻟﺗﻘدﯾم ﻋﻠﻰ اﻟﺑطﺎﻗﺔ اﻟﺗﻣوﯾﻧﯾﺔ اﻻﻟﻛﺗروﻧﯾﺔ ﻋن طرﯾق ﺗﻧزﯾل ﺗطﺑﯾق اﻟﮭﺎﺗف اﻟذﻛﻲ واﺗﺑﺎع ﺧطوات اﻟﺗﺳﺟﯾل"])},
    "q18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﻛﯾف ﯾﻣﻛﻧﻧﻲ اﻟﺗﺳﺟﯾل اذا ﻛﺎﻧت ﻟدي ﺑطﺎﻗﺔ ﺗﻣوﯾﻧﯾﺔ ﺟدﯾدة )ﻏﯾر ورﻗﯾﺔ(؟"])},
    "a18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﻣﻛن اﻟﺗﺳﺟﯾل ﻋن طرﯾق ﺗﺣﻣﯾل ﺗطﺑﯾق اﻟﮭﺎﺗف اﻟذﻛﻲ واﺧﺗﯾﺎر \"ﺑطﺎﻗﺔ ﺟدﯾدة\" واﻛﻣﺎل اﻟﺗﻘدﯾم ﺑﺎﺗﺑﺎع ﺧطوات اﻟﺗﺳﺟﯾل"])},
    "howtouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الاستخدام"])},
    "q19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اﺣد اﻓراد اﻟﻌﺎﺋﻠﺔ ﻏﯾر ﻣﺗواﺟد ﻗرﯾﺑﺎ ﻣن اﻻﺳرة ﻛﯾف ﯾﻣﻛﻧﻧﻲ ﺗﺳﺟﯾﻠﮫ؟"])},
    "a19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﻣﻛن ارﺳﺎل راﺑط اﻟﺗدﻗﯾق ﻋن طرﯾق ﻧﺳﺧﮫ وارﺳﺎﻟﮫ اﻟﻰ اي ﻣﻧﺻﺔ ﺗﻣﻛﻧك ﻣن اﻟﺗواﺻل ﻣﻊ ﺻﺎﺣب اﻟﺑطﺎﻗﺔ واﺗﺑﺎع ﺧطوات اﻟﺗدﻗﯾق"])}
  },
  "rules": {
    "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والاحكام"])},
    "ami": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انا اتعهد "])},
    "section1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعد قبل البدء بعملية التسجيل ستحتاج الى الوثائق الشخصية الثبوتية الخاصة بك ولعائلتك اثناء عملية التسجيل."])},
    "section2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالتأكد من ادخال معلوماتك الصحيحة وفي حالة استخدامك لمعلومات وهمية او لمعلومات شخص آخر او اخفاء اية معلومات مطلوبة فستعرض نفسك الى المحاسبة القانونية والعقوبات القضائية."])},
    "checkbox1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بصحة رقم الهاتف المقدم عن طريقه و ان رقم الهاتف يعمل بشكل صحيح و ان لا اقدم اكثر من مرة "])},
    "checkbox2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بالتبليغ اذا كنت انا او احد افراد عائلتي من الفئات المشمولين بقرار مجلس الوزراء رقم ١٠٥ لسنة ٢٠٢١ (المقاولين - اصحاب الشركات - المساهمين في اتحاد رجال الاعمال - المسجلين ضمن نقابة الاطباء و اطباء الاسنان و الصيادلة)"])},
    "checkbox3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بانني لا اتقاضى انا او احد افراد عائلتي راتبا كليا يزيد عن مليونين دينار"])},
    "checkbox4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بتقديم المستمسكات الاصولية لافراد عائلتي التي تجهز بالمواد الغذائية و ابلغ عن (المسافرين - الوهميين - المتوفين - نزلاء السجون المركزية - العناصر المنتمين الى تنظيمات ارهابية - الهاربين من العدالة) و بخلاف ذلك اتحمل كافة التبعات القانونية وفق المادة (٢٤٥) من قانون العقوبات رقم (١١١) و لاجله وافقت"])},
    "section3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص المادة (٢٤٥) من قانون العقوبات: 'يعاقب بالحبس مدة لا تزيد على سنة واحدة وبغرامة مالية او باحدى هاتين العقوبتين كل من كان ملزما قانونا باخبار احد المكلفين بخدمة عامة بصفته الرسمية عن امر فاخبره بامور يعلم انها كاذبة وكل من اخبر احد المكلفين بخدمة عامة بصفته الرسمية بامور يعلم بانها كاذبة قاصدا بذلك حمله على عمل شيء او الامتناع عن عمل خلافا لما كان يجب عليه القيام به لو ان حقيقة الواقع كانت معلومات لديه'"])},
    "note1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* كافة المعلومات التي ستقوم بإدخالها سيتم تدقيقها من قبل الجهات الأمنية."])},
    "note2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* ان قيامك بعملية التسجيل يعني تعهدك بقراءة وفهم هذه التعليمات وموافقتك على الشروط والأحكام المتعلقة بالبطاقة التموينية."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موافق"])}
  },
  "login": {
    "regsternumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل رقم هاتفك"])},
    "phonenumbermustbe11number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف يجب أن يتكون من 11 رقم"])},
    "citis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
    "karbala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كربلاء المقدسة "])},
    "ninwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نينوى "])},
    "arbil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أربيل "])},
    "mthna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المثنى "])},
    "misan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميسان "])},
    "krkok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كركوك "])},
    "dhok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دهوك "])},
    "diala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ديالى "])},
    "basra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البصرة "])},
    "baghdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بغداد "])},
    "babil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بابل "])},
    "anbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانبار "])},
    "qadsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القادسية "])},
    "salahdin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاح الدين "])},
    "sulimany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السليمانية "])},
    "najaf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النجف الأشرف "])},
    "thiqar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذي قار "])},
    "wast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واسط"])},
    "selectfromlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر من القائمة"])},
    "sorryappruninkarbakaonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عذراً، المنصة فعالة فقط في محافظة كربلاء المقدسة حالياً"])},
    "reqster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب جدید"])},
    "youhaveaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك حساب؟ "])},
    "enterheretoenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا للدخول"])},
    "enterphonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم هاتفك"])},
    "sendotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال الرمز"])},
    "clecktoback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا للعودة"])},
    "confirmpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التأكيد"])},
    "resendpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعد ارسال الرمز "])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
    "changephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير رقم الهاتف؟ "])},
    "clickhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا"])},
    "cardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة التموینیة"])},
    "entercardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الرقم"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "enterpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور"])},
    "forgetpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور؟ "])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "noaccounthave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب؟ "])},
    "clickheretoreqster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا للتسجيل"])},
    "entertmwinyainfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال المعلومات التالية الموجودة في البطاقة التموينية الخاصة بك"])},
    "tmwincardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة التموينية"])},
    "numbertmwincenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المركز التمویني"])},
    "numberfoodag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم وكيل الغذائية"])},
    "numberfluerag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم وكيل الطحين"])},
    "personalinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الشخصية لرب الأسرة "])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتمل"])},
    "personinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الشخصية"])},
    "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول "])},
    "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الثاني"])},
    "name3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الثالث"])},
    "name4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الرابع"])},
    "requerfiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحقل مطلوب"])},
    "suername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب (اختياري) "])},
    "mothername1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الام"])},
    "mothername2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم اب الام"])},
    "mothername3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم جد الام"])},
    "gander": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس "])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر "])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انثى"])},
    "berthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
    "socualstate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة الاجتماعية"])},
    "qtha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القضاء"])},
    "nahia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الناحية"])},
    "marid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متزوج/متزوجة "])},
    "singal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعزب /عزباء"])},
    "armal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرمل /ارملة"])},
    "motalg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطلق /مطلقة"])},
    "hometype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع السكن"])},
    "zrai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طابو زراعی "])},
    "ejar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايجار"])},
    "tjawz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجاوز"])},
    "sfih": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيوت صفيح"])},
    "nazh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نازح في مخيمات"])},
    "aqarb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسكن مع أقارب"])},
    "jobtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العمل"])},
    "privet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاع خاص"])},
    "publice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" قطاع عام"])},
    "kasib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عاطل / كاسب"])},
    "motga3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقاعد"])},
    "cardidtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع البطاقة التعريفية"])},
    "nationalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البطاقة الوطنية"])},
    "oldedid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هوية الأحوال المدنية"])},
    "cardidnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهوية"])},
    "pagenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الصحيفة"])},
    "lognumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم السجل"])},
    "informationOffec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتب المعلومات"])},
    "imgidcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة البطاقة الوطنية او الهوية"])},
    "deleteimg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الصورة"])},
    "frontid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوجه الأمامي"])},
    "backid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوجه الخلفي"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "familyinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات افراد الاسرة"])},
    "withrbosra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلاقة برب الاسرة "])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زوج"])},
    "wife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" زوجة"])},
    "son": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابن/ بنت"])},
    "parint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ام/ اب"])},
    "brother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخ/ اخت"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخرى"])},
    "jobinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات العمل"])},
    "socualcera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لدى الفرد راتب رعاية اجتماعية؟"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم "])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "enterforenglishnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الادخال مسموح للأرقام الإنجليزية فقط"])},
    "enterinarabiconly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الادخال مسموح فقط باللغة العربية"])},
    "familynumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم العائلي في البطاقة الوطنية"])},
    "enterforenglishletterandnumberonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الادخال مسموح للارقام والاحرف الإنكليزية فقط"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهة الإصدار"])},
    "seeditails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاينة تفاصيل الإستمارة"])},
    "cardinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات البطاقة"])},
    "newcardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة الجديد"])},
    "oldcardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة القديم"])},
    "centernumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المركز"])},
    "adddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإضافة"])},
    "homeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات السكن"])},
    "aqrabpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقرب نقطة دالة"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرد "])},
    "cardornumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة او الهوية "])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرفقات"])},
    "bookdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجز موعد"])},
    "enterdateday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الضغط على العنوان الأنسب لك لغرض تدقيق معاملتك "])},
    "nobook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مواعيد للحجز"])},
    "invaliddata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات المدخلة غير صحيحة"])},
    "validfromenterddata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء التأكد من معلومات البطاقة التموينية"])},
    "confermcodeinvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التأكيد غير صحيح"])},
    "confermsmsmasage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء التأكد من ادخالك الرمز المستلم عبر رسالة sms"])},
    "invalidllogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل تسجيل الدخول"])},
    "enternumberandpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم المستخدم والرمز السري المرسل اليك عبر رسالة نصية"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبيه"])},
    "confermvalidotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء التأكد من ادخالك الرمز المستلم عبر رسالة نصية"])},
    "createaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء حساب"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "conferm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "oksubmet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التقديم"])},
    "endsubmet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انهاء التقديم"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ "])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدخل الشهري"])},
    "bookyourdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حجز موعدك"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحد"])},
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاثنين"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثلاثاء"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأربعاء"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخميس"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمعة"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
    "plscom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى حضور جميع افراد الاسرة اللذين تتجاوز اعمارهم الـ 18 سنة فما فوق وجلب المستمسكات الثبوتيه لاكمال عملية التدقيق بصورة ناجحة"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة"])},
    "editdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتعديل الموعد"])},
    "thedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموعد"])},
    "plshome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الانتظار حتى يصلك فريقنا المختص لإكمال معاملتك من منزلك"])},
    "verfictioncode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم ارسال رمز التحقق الى هاتفك "])},
    "usedphonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم مستخدم"])},
    "codearydesend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال الرقم السري الى هذا الرقم بالفعل "])},
    "sendvalidnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد من ادخال رقم صحيح"])},
    "usernameorpasswordinvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الحساب او كلمة المرور غير صحيحة"])},
    "confermenterfeilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد من ادخال الحقول المطلوبة "])},
    "resendin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة أرسال في "])},
    "secnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانية"])},
    "resendcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعد ارسال الرمز"])},
    "verfiecodenotcorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التاكيد غير صحيح"])},
    "confermtoenterotpcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد من ادخال رمز التاكيد"])},
    "confirmpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد كلمة المرور "])},
    "enterconfempasswordagine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل كلمة المرور مرة اخرى"])},
    "notconfermpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور غير متطابقة"])},
    "changepasswordseccses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير كلمة المرور بنجاح"])},
    "passwordmustbe6ormore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كلمة المرور يجب ان تتكون من 6 عناصر او اكثر"])},
    "tmwincentermusthave3numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يتكون رقم المركز التمويني من 3 ارقام"])},
    "foodcenetrmusthave6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يتكون رقم الوكيل  من 6 ارقام"])},
    "tmwinyacardmusthave7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يتكون رقم البطاقة التموينية من 7 ارقام"])},
    "cardnotexsit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البطاقة غير موجودة تاكد من ادخال معلومات صحيحة"])},
    "familynotcomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد افراد لم تكتمل معلوماتهم"])},
    "uncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مكتمل"])},
    "yyyymmdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم/ شهر/ سنة"])},
    "formalreadyexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البطاقة مرتبطة برقم اخر"])},
    "aremainfromhimam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل رب الاسرة من ذوي الهمم ؟ "])},
    "jobtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان الوظيفي "])},
    "jobnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الوظيفي"])},
    "jobImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة لهوية العمل"])},
    "jobIdCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة البطاقة"])},
    "careImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة لبطاقة الرعاية الاجتماعية"])},
    "cardImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الهوية"])},
    "nationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة الوطنية"])},
    "exportdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الأصدار"])},
    "tmwincardmusthave12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة الوطنية يجب ان يتكون من 12 رقم"])},
    "cardidgnsiamusthave8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهوية يجب ان يتكون من 8 ارقام"])},
    "lognumbermusthave4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم السجل يجب ان يتكون من رقم الى 8 ارقام"])},
    "pagenumbermusthave4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الصحيفة يجب ان يتكون من رقم الى 8 ارقام"])},
    "familynumbermusthave18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم العائلي يجب ان يتكون من 18 رقم وحرف"])},
    "familynumbermusthaveletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يحتوي الرقم العائلي على حرف او اكثر"])},
    "mlksrf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملك صرف"])},
    "imgdeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الصورة "])},
    "imgnotdeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم حذف الصورة "])},
    "imgadded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة الصورة "])},
    "imgnotadded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لم يتم اضافة الصورة تاكد من نوع الصورة"])},
    "invalidinworkinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد خطا في معلومات العمل ...  يرجى  التاكد من المدخلات"])},
    "invalidinnationaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد خطا في معلومات البطاقة ...  يرجى  التاكد من المدخلات"])},
    "invalidincardid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد خطا في معلومات الهوية ...  يرجى  التاكد من المدخلات"])},
    "dataseved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ المعلومات"])},
    "invailddata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد خطا في المعلومات ...  يرجى  التاكد من المدخلات"])},
    "qraba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلة القرابة"])},
    "invalidimage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد من رفع صورة البطاقة التعريفية"])},
    "invaliddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد من ادخال تاريخ الميلاد"])},
    "imgsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الملف يجب أن يكون بين 50 كيلو بايت و 5 ميجا بايت"])},
    "loginagine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستصلك رسالة بمعلومات حسابك"])},
    "invaildreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطا "])},
    "PRE_NATIONALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة قيد البطاقة الوطنية"])},
    "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])}
  }
}